import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import Navigation from "./navigator/Navigation";

import { loadUser } from "./store/actions/authActions";
import { loadRecentlyViewedProducts } from "./store/actions/recentViewedProductsActions";

import "./App.css";
import AuthModal from "./components/Auth/AuthModal";
import MoveTop from "./components/Common/MoveTop";
import MainLayout from "./layout/MainLayout";
import { useDispatch } from "react-redux";
// import SideCartModal from "./components/Cart/SideCartModal";
import {Helmet} from "react-helmet";
import { useLocation , useSearchParams } from 'react-router-dom';
import { appGetSEODetails } from "./services/PageServices.js";

function App() {
  const dispatch = useDispatch();
  const [seoDetails, setSeoDetails] = useState({});
  const [searchParams] = useSearchParams();
  const [utmSource , setUtmSource] = useState(searchParams?.get("utm_source") || "");

  // console.log("Test App.js UTM source : " , utmSource.toLowerCase() );


  if( utmSource != "" ){
    localStorage.setItem('Utm_source' , utmSource.toLowerCase());
    setUtmSource("");
  }

  dispatch(loadUser());
  dispatch(loadRecentlyViewedProducts());
  // let response = {};
  const location = useLocation();

  /**
   * Fetching SEO meta data
   */
  useEffect(() => {
    appGetSEODetails({
      page_url: location.pathname
    }).then((res) => {
      let response = JSON.parse(res?.data);
      setSeoDetails(response.data);
    });
  }, [location]);

  return (
    <div className="App">
      <Helmet>
        <meta name="keywords" content={seoDetails.keywords} />
        <meta name="description" content={seoDetails.meta_desc} />
        <meta name="robots" content={seoDetails.robots} />
        <link rel="canonical" href={seoDetails.canonical_url} />
        <title>{seoDetails.title}</title>
      </Helmet>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        // pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ position: "fixed", zIndex: "99999999999" }}
        // limit={1}
      />

      <AuthModal />
      {/* <SideCartModal /> */}
      {/* Routing Section */}
      {/* <MainLayout > */}
      <MainLayout seoDetails={seoDetails} >
        <Navigation />
      </MainLayout>

      <MoveTop />
    </div>
  );
}

export default App;
