import React, { useEffect, useState } from "react";
import { ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import {
  appGetCartDetails,
  appRemoveProduct,
  appUpdateCartQuantity,
  appUpdateProductSize,
} from "../../services/CartServices";
import { appAddToWishlist } from "../../services/WishlistService";
import { clearPopups } from "../../store/actions/popupActions";
import { MOBIEL_VIEW_WIDTH, SUB_TOTAL_KEY } from "../../utils/Constant";
import CartItemOptionHtml from "./CartItemOptionHtml";
import EmptyCart from "./EmptyCart";
import Placeholder from "../Placeholders/Placeholder";
  
import "./SideCartModal.css";
import ProductPrice from "../product/ProductPrice";
import { Link, useNavigate , useSearchParams } from "react-router-dom";
import { addCartCount } from "../../store/actions/cartActions";
import { useSnackbar } from 'react-simple-snackbar';
import {addWishlistCount} from "../../store/actions/authActions";

Modal.setAppElement("body");

function SideCartModal({ openModal = false }) {

  const [searchParams] = useSearchParams();


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(openModal);
  const [cartDetails, setCartDetails] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const [removeItem, setRemoveItem] = useState({});
  const [isOpenRemoveItemBox, setIsOpenremoveItemBox] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [openSnackbar] = useSnackbar();

  const popupState = useSelector((state) => state.popup);
  const cartCountState = useSelector((state) => state.cartCount);
  const authState = useSelector((state) => state.auth);

  useEffect(() => {
    if(popupState?.popupId === 'sidecart') {
      setIsModalOpen(true);
    }
  }, [popupState]);

  function handleWindowSize() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSize);
    return () => {
      window.removeEventListener("resize", handleWindowSize);
    };
  }, []);

  let isMobile = width <= MOBIEL_VIEW_WIDTH;

  useEffect(() => {
    if (isModalOpen) {
      getCartDetails();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isModalOpen]);

  const getCartDetails = () => {
    setShowSkeleton(true);
    appGetCartDetails()
      .then((res) => {
        res = JSON.parse(res.data);
        dispatch(addCartCount(res?.cart?.total_cart_items || 0));
        setCartDetails(res);
      })
      .finally(() => setShowSkeleton(false));
  }

  const toggle = () => {
    if(isModalOpen) {
      dispatch(clearPopups())
    }
    setRemoveItem({});
    setIsOpenremoveItemBox(false);
    setIsModalOpen(!isModalOpen);
  };

  const sideCartModalCb = (openModal) => {
    setIsModalOpen(openModal);
  };

  const onCheckoutClick = () => {
    dispatch(clearPopups());
    setIsModalOpen(false);
    // return navigate("/checkout");
    return navigate(`/checkout?${searchParams.toString()}`);
  };

  const customStyles = {
    content: {
      inset: "unset",
      height: "100%",
      position: "fixed",
      margin: "auto",
      width: "430px",
      padding: '0px'
    },
    overlay: { zIndex: "9999999", backgroundColor: "rgb(0 0 0 / 75%)" },
  };

  const onSizeChange = (elem, cartItem) => {
    let cartProducts = {
      size_id: elem?.value,
      cart_id: cartItem?.cart_id,
      color_id: cartItem?.selected_color_id,
      product_id: cartItem?.product_id,
    };

    appUpdateProductSize(cartProducts).then((res) => {
      res = JSON.parse(res?.data);

      if (res.status === "success") {
        // toast.success(res.message);
        openSnackbar(res.message);
      } else {
        // toast.error(res.message);
        openSnackbar(res.message);
      }
      getCartDetails();
    });
  };

  const onQuantityChange = (elem, cartItem) => {
    let cartProducts = {
      cart_products: [
        {
          cart_id: cartItem?.cart_id,
          quantity: elem.value,
          id_product_attribute: 0,
          id_customization_field: "",
        },
      ],
    };

    appUpdateCartQuantity(JSON.stringify(cartProducts)).then((res) => {
      res = JSON.parse(res?.data);

      if (res.status === "success") {
        // toast.success(res.message);
        openSnackbar(res.message);
      } else {
        // toast.error(res.message);
        openSnackbar(res.message);
      }

      getCartDetails();
    });
  };

  // Open Confirmation Remove Item Dialog
  const setRemoveItemFromCart = (item) => {
    if (item) {
      setRemoveItem(item);
      setIsOpenremoveItemBox(true);
    }
  };

  // Call RemoveProduct From Cart API to remove item from cart
  const onRemoveCartItem = (wishlist = false) => {
    if(removeItem) {
      setIsOpenremoveItemBox(false);
      setShowSkeleton(true);

      const cartProducts = {
        cart_products: [
          {
            cart_id: removeItem?.cart_id,
            quantity: removeItem?.quantity,
            id_product_attribute: removeItem?.id_product_attribute,
            id_customization_field: removeItem?.customizable_items,
          },
        ],
      };

      appRemoveProduct(JSON.stringify(cartProducts)).then((res) => {
        res = JSON.parse(res.data);
        if (res.status === "success") {
          getCartDetails();
          // toast.success(res.message || "");
          openSnackbar(res.message || "");
          setRemoveItem({});
        } else {
          // toast.error(res.message || "");
          openSnackbar(res.message || "");
          setRemoveItem({});
        }
      });
    }
  }

  // Add cart Item to wishlist and then remove item from cart
  const onWishlistItem = () => {
    if(removeItem) {
      // wishlist product 
      appAddToWishlist(removeItem.product_id).then(res => {
        res = JSON.parse(res.data);
        if(res.status === 'success') {
          // toast.success(res.message || "");
          openSnackbar(res.message || "");
          onRemoveCartItem(true);
          let loginUser = authState?.user;
          loginUser.wishlist_count = res?.wishlist_count;
          dispatch(addWishlistCount(loginUser));
        }
        else {
          // toast.error(res.message || "");
          openSnackbar(res.message || "");
        }

      })
    }
  }

  return (
    <>
      {isMobile ? (
        <button onClick={(cartCountState?.cartCount > 0)? onCheckoutClick:toggle} className="mb-wishlist btn-blank">
          {/* <img
            src={process.env.PUBLIC_URL + "/assets/images/icons/Cart-Icon.png"}
            alt=""
          /> */}

          {cartCountState?.cartCount > 0 && (
            <span className="badge badge-top custom-badge">
              {cartCountState?.cartCount || ""}
            </span>
          )}

          {cartCountState?.cartCount > 0 ? 
          (
            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/dark-cart.png"
              }
              alt=""
            />
          )
          :
          (
            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/Cart-Icon.png"
              }
              alt=""
            />
          )
          }

        </button>
      ) : (
        <button type="button" className="btn-blank" onClick={toggle}>
          {/* <img
            src={
              process.env.PUBLIC_URL + "/assets/images/icons/shopping-bag.svg"
            }
            alt=""
          /> */}
          {cartCountState?.cartCount > 0 && (
            <span className="badge badge-top custom-badge">
              {cartCountState?.cartCount || ""}
            </span>
          )}

          {cartCountState?.cartCount > 0 ? 
          (
            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/dark-cart.png"
              }
              alt="" className="dart-cart"
            />
          )
          :
          (
            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/shopping-bag.svg"
              }
              alt=""
            />
          )
          }

        </button>
      )}

      <Modal
        portalClassName={"side_cart_modal"}
        closeTimeoutMS={300}
        isOpen={isModalOpen}
        toggle={toggle}
        style={customStyles}
        onRequestClose={toggle}
        shouldCloseOnOverlayClick={true}
        id="side_cart_modal"
      >
        <ModalHeader>
          <div className="d-flex">
            <button onClick={toggle} className="btn-blank btn-side-cart-close">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/icons/Cross-Button.png"
                }
                className="close-btn-newsletter"
                alt=""
              />
            </button>

            {showSkeleton ? (
              <h3 className="">
                <Placeholder variant="text" width="200px" className="mx-auto" />
              </h3>
            ) : (
              cartDetails && (
                // cartDetails?.cart?.total_cart_items >= 1 && (
                <h3 className="">
                  {/* Added to cart  */}
                  Shopping bag
                  {cartDetails?.cart?.total_cart_items ? "(" + cartDetails?.cart?.total_cart_items + ")" : ""}
                  {/* ({cartDetails?.cart?.total_cart_items || ""}) */}
                </h3>
              )
            )}
            { }
          </div>
          {cartDetails?.show_cart_error && (
            <span className="text-danger">*{cartDetails?.out_of_stock_purchase_error_message}</span>
          )}
        </ModalHeader>
        <ModalBody>
          {showSkeleton ? (
            <>
              <div className="cart-text">
                {Array.from(Array(5), (e, i) => {
                  return (
                    <div className="item-box mt-40" key={`cart-item-${i}`}>
                      <button
                        className="btn-blank close-btn"
                        onClick={(e) => e.preventDefault()}
                      >
                        <Placeholder
                          variant="circular"
                          width="30px"
                          height="30px"
                        />
                      </button>
                      <div className="left-img">
                        <Placeholder
                          variant="rectangular"
                          width="100px"
                          height="100px"
                          className="img-fluid mx-auto d-block"
                        />
                      </div>
                      <div
                        className="desc text-left mt-10"
                        style={{ marginLeft: "10px" }}
                      >
                        <h4>
                          <Placeholder variant="text" width="auto" />
                        </h4>
                        <p>
                          <Placeholder variant="text" width="auto" />
                        </p>
                        <p>
                          <Placeholder variant="text" width="auto" />
                        </p>
                        <div className="drpdwns">
                          <Placeholder
                            variant="rectangular"
                            width="100%"
                            height="40px"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="subtotal-cart text-center">
                <Placeholder variant="text" width="50%" className="mx-auto" />

                <Placeholder variant="text" width="auto" height="80px" />
                <Placeholder variant="text" width="50%" className="mx-auto" />
              </div>
            </>
          ) : cartDetails && cartDetails?.cart?.total_cart_items >= 1 ? (
            <>
              <div className="cart-text">
                {cartDetails.products?.map((cartItem, index) => {
                  return (
                    <div className="item-box mt-40" key={`cart-item-${index}`}>
                      <button
                        className="btn-blank close-btn"
                        onClick={(e) => setRemoveItemFromCart(cartItem)}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/Grey-Cross-Button.png"
                          }
                          alt=""
                        />
                      </button>
                      <div className="left-img">
                        <Link to={`/product/${cartItem.url_path}`}>
                          <img
                            src={cartItem?.images}
                            className="img-fluid mx-auto d-block"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="desc text-left">
                        <h4>{cartItem?.designer_name}</h4>
                        <p>{cartItem?.title}</p>
                        <ProductPrice
                          price={cartItem.price}
                          show_price={true}
                          discount_price={cartItem.discount_price}
                          discount_perc={cartItem.discount_percentage}
                          show_discount_price={cartItem.show_discount_price}
                          show_discount_perc={false}
                        />
                        <div className="drpdwns">
                          <CartItemOptionHtml
                            cartItem={cartItem}
                            option={
                              cartItem?.product_items?.find(
                                (i) => i.name === "Quantity"
                              ) || {}
                            }
                            onQuantityChange={onQuantityChange}
                            onSizeChange={onSizeChange}
                          />

                          <CartItemOptionHtml
                            cartItem={cartItem}
                            option={
                              cartItem?.product_items?.find(
                                (i) => i.name === "Size"
                              ) || {}
                            }
                            onQuantityChange={onQuantityChange}
                            onSizeChange={onSizeChange}
                          />
                        </div>
                        {!cartItem?.stock && (
                          <p className="text-danger">*{cartItem?.sold_out_message}</p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              {isOpenRemoveItemBox && (
                <div className="remove-box mt-20">
                  <button
                    className="btn-blank close-btn"
                    onClick={(e) => setIsOpenremoveItemBox(false)}
                  >
                    {/**
                     * BOC
                     * Task#143723-Need to fix the UI design issues
                     * author Dixit Chauhan
                     * date 13-Dec-2023
                     * use_of_code: Please change cross X icon.
                     */}
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/Cross-Icon-New.png"
                      }
                      alt=""
                    />
                    {/**
                      * EOC 
                      */}
                  </button>
                  <div className="text-center">
                    <img
                      src={removeItem?.images || ""}
                      className="small-left-img"
                      alt=""
                    />
                    <p className="text-black">
                      Remove item from bag
                      <span className="questionmark">?</span>
                    </p>
                  </div>
                  <div className="action-buttons mt-20 d-flex justify-content-center">
                    <button
                      className="btn-bordered"
                      type="button"
                      onClick={onWishlistItem}
                    >
                      ADD TO WISHLIST
                    </button>
                    <button
                      className="btn-bordered rmv"
                      type="button"
                      onClick={onRemoveCartItem}
                    >
                      REMOVE
                    </button>
                  </div>
                </div>
              )}

              <div className="subtotal-cart text-center">
                <h4>
                  {"SUB TOTAL : "}
                  {cartDetails?.totals?.filter(
                    (x) => x.name === SUB_TOTAL_KEY
                  )[0]?.value || ""}
                </h4>
                <button
                  className="btn-primary mt-20"
                  onClick={() => onCheckoutClick()}
                  style={{ width: "100%" }}
                >
                  CHECKOUT
                </button>
                <button
                  className="btn-blank mt-20 text-underline"
                  // onClick={() => setIsModalOpen(false)}
                  onClick={toggle}
                >
                  CONTINUE SHOPPING
                </button>
              </div>
            </>
          ) : (
            <EmptyCart sideCartModalCb={sideCartModalCb} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default SideCartModal;
